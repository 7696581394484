const getSettings = (right, left) => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: right,
    prevArrow: left,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          centered: true,
        },
      },
    ],
  }
  return settings
}

const carouselData = [
  {
    heading: "185,600+",

    desc: "Novel Insights Generated",
  },
  {
    heading: "47,200+",

    desc: "Opportunities to Flourish Identified",
    flourish: true,
  },
  {
    heading: "1,900+ yrs",

    desc: "Productive Time Loss Identified",
  },
]
const carouselDataMob = [
  {
    heading: "47,200+",

    desc: "Opportunities to Flourish Identified",
    flourish: true,
  },
  {
    heading: "185,600+",

    desc: "Novel Insights Generated",
  },

  {
    heading: "1,900+ yrs",
    desc: "Productive Time Loss Identified",
  },
]

export { getSettings, carouselData, carouselDataMob }
